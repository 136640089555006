





























import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import PageRequest from '@/store/entities/page-request';
import Attendance from '@/store/entities/courses/attendance';
import ListView from '@/components/helper/list-view.vue';
import ModalView from '@/components/helper/modal-view.vue';
import FormBase from '@/components/helper/form-base.vue';


class PageAttendanceRequest extends PageRequest { }

@Component({
    components: { ListView, ModalView, FormBase }
})
export default class Attendances extends ListBase {
    entity: string = 'attendance';
    pagerequest: PageAttendanceRequest = new PageAttendanceRequest();
    attendance: Attendance = new Attendance();
    formBase: Boolean = true;
    lastRow: Object = null;

    get loading() {
        return this.$store.state.attendance.loading;
    };
    
    itemMethods = {
        groupType: (data) => {
        }
    }
    items = [ 
        this.gItem({ type: 'text', prop: 'name', label: 'Nombre', size: 6, required: true }),
        this.gItem({ type: 'select', prop: 'group_type', label: 'Tipo de atributo', options: 'attr_group', size: 6, returnObject: false, required: true, onchange: this.itemMethods.groupType  }),                 
    ]

    columns = [
        this.gColO({ type: 'text', value: 'name', text: 'Nombre', editableOnClick: true, save: this.update }),
        this.defaultActions('delete') 
    ] 

    onRowClick(row: any, index: any) {
        this.lastRow = row;
        (this.$refs.valuesView as any).title = this.L('Valores de ') + row.name;
        (this.$refs.valuesView as any).selected = true;
        this.$store.commit('attribute/setList', row);
    }

    getpage() {
        (this.$refs.entityForm as any).reset();
        (this.$refs.valuesView as any).title = this.L('Valores');
        (this.$refs.valuesView as any).selected = false;
        this.$store.commit('attribute/setList', null);
    }

    async save() {
        if (this.$refs.entityForm != undefined && (this.$refs.entityForm as any).validate()) {
            let model = this.attendance;
            await this.$store.dispatch({
                type: 'attendance/create',
                data: model
            });
            (this.$refs.entityForm as any).reset();
            let list: any = this.$refs.listView
            Util.abp.notify.success(this, 'Atributo Creado Correctamente!');
            await list.getpage();
        }
    }

    async update(data) {
        await this.$store.dispatch({
            type: 'attendance/update',
            data: data
        });
        Util.abp.notify.success(this, 'Atributo Actualizado!');
    }

    async created() {
        //this.getpage();
    }
}
